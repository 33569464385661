import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "src/theme/variables";
import { mediaMd, transition } from "src/theme/utils";
import { Typography, Link } from "src/theme/elements";
import { Container } from "src/theme/layout";

const StyledTypography = styled(Typography)``;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link).attrs({
  activeClassName: "link-active",
})`
  position: relative;
  padding: 0 8px;
  margin: 0 auto;
  color: ${colors.lightGray};
  transition: ${transition("color")};
  font-size: 21px;

  ${mediaMd`
    font-size: 18px;
  `}

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 4px);
    opacity: 0;
    width: calc(100% - 16px);
    height: 3px;
    background-color: ${colors.crimson};
    transition: ${transition(["transform", "opacity"])};

    ${mediaMd`
      width: calc(100% - 12px);
    `}
  }

  ${StyledTypography} {
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    color: inherit;

    ${mediaMd`
      font-size: 21px;
    `}
  }

  &.link-active,
  &:active,
  &:hover {
    color: ${colors.crimson};

    &::after {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
`;

const StyledRoot = styled.header`
  background-color: black;
  padding: 12px 0;
  transition: ${transition("background-color")};

  ${StyledLink} {
    margin: auto;
    color: white;
  }
`;

const Header = ({ className }) => {
  return (
    <StyledRoot className={className}>
      <StyledContainer>
        <StyledLink to="https://supportukrainenow.org/">
          STOP WAR IN UKRAINE 🇺🇦
        </StyledLink>
      </StyledContainer>
    </StyledRoot>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
