import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Typography, Button, Svg } from "src/theme/elements";
import { Container } from "src/theme/layout";
import { colors } from "src/theme/variables";
import { mediaLg, mediaSm } from "src/theme/utils";

const StyledRoot = styled.section`
  background-color: ${colors.white};

  ${({ page }) => {
    switch (page) {
      case "index":
        return css`
          ${StyledContainer} {
            padding-top: 80px;
            padding-bottom: 50px;

            ${mediaSm`
              padding-bottom: 37px;
            `}
          }
        `;
      default:
        return "";
    }
  }}
`;

const StyledDuck = styled(Svg).attrs({
  path: "images/artwork-duck-flying",
})`
  position: absolute;
  z-index: 2;
  top: -32px;
  left: 40px;
  width: 272px;

  ${mediaLg`
    width: 217px;
  `}

  ${mediaSm`
    top: -16px;
    width: 195px;
    left: -45px;
  `}
`;

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 1;
`;

const StyledTrial = styled.div`
  position: relative;
  background-image: linear-gradient(to bottom, #e43c58, #ff6983);
  border-radius: 16px;
  padding: 120px 50px 123px;
  overflow: hidden;
`;

const StyledLines = styled(Svg).attrs({
  path: "images/artwork-fastlines",
  preserveAspectRatio: "none",
})`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${mediaSm`
    min-width: 720px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  `}
`;

const StyledContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledAccent = styled(Typography).attrs({
  settings: {
    dark: true,
  },
})`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;
  text-align: center;

  ${mediaSm`
    display: none;
  `};
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h2",
    dark: true,
  },
})`
  margin-bottom: 32px;
  text-align: center;

  ${mediaSm`
    margin-bottom: 21px;
  `}
`;

const StyledButton = styled(Button).attrs({
  variant: "inverted",
})`
  margin-bottom: 11px;
  min-width: 280px;

  ${mediaSm`
    min-width: 200px;
  `}
`;

const StyledHint = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.white};
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
`;

const Trial = ({ page }) => (
  <StyledRoot page={page}>
    <StyledContainer>
      <StyledDuck />
      <StyledTrial>
        <StyledLines />
        <StyledContent>
          <StyledAccent>Become unstoppable</StyledAccent>
          <StyledTitle>Start your 14-day free trial</StyledTitle>
          <StyledButton to="/signup">Start Trial</StyledButton>
          <StyledHint>No credit card required</StyledHint>
        </StyledContent>
      </StyledTrial>
    </StyledContainer>
  </StyledRoot>
);

Trial.propTypes = {
  page: PropTypes.oneOf(["index"]),
};

export default Trial;
