import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mediaSm, mediaLg } from "src/theme/utils";
import { Typography, Button, Svg, ThemeLink } from "src/theme/elements";
import { openCrisp } from "src/utils";
import { colors, fonts } from "src/theme/variables";

const StyledTypography = styled(Typography)``;

const StyledRoot = styled.div`
  position: relative;
  border-width: 8px;
  border-style: solid;
  border-color: ${colors.green};
  border-radius: 30px;
  padding: 40px 40px 40px 55px;
  background-color: ${colors.white};

  ${mediaLg`
    padding: 38px 40px 40px;
  `}

  ${mediaSm`
    border-width: 6px;
    border-radius: 24px;
    padding: 33px 24px 44px 24px;
  `}
`;

const StyledDuck = styled.div`
  position: absolute;
  top: 0;
  right: 36px;
  transform: translateY(-50%);
  background-color: ${colors.white};
  width: 195px;
  padding-left: 24px;
  padding-right: 12px;

  ${mediaLg`
    width: 144px;
    right: 30px;
    padding-left: 4px;
    padding-right: 0;
  `}

  ${mediaSm`
    width: 124px;
  `}
`;

const StyledLockIcon = styled(Svg).attrs({
  path: "icons/lock",
})`
  display: block;
  height: 40px;
  margin-bottom: 21px;
  color: ${colors.green};

  ${mediaLg`
    margin-bottom: 26px;
  `}

  ${mediaSm`
    height: 32px;
    margin-bottom: 23px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h4",
    dark: true,
  },
})`
  margin-bottom: 30px;

  ${mediaLg`
    margin-bottom: 18px;
  `}

  ${mediaSm`
    margin-bottom: 22px;
  `}
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 21px;

  ${mediaLg`
    margin-bottom: 34px;
  `}
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 12px;

    ${mediaSm`
      margin-bottom: 20px;
    `}
  }

  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: ${colors.green};
    margin: 7px 18px 7px 0;
    border-radius: 50%;

    ${mediaSm`
      margin-right: 12px;
    `}
  }

  ${StyledTypography} {
    flex: 1;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    font-family: ${fonts.secondary};
  }
`;

const StyledBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaSm`
    flex-direction: column;
    justify-content: center;
  `}
`;

const StyledThemeLink = styled(ThemeLink)`
  ${mediaSm`
    margin-bottom: 24px;
  `}
`;

const ListItem = ({ children }) => (
  <StyledListItem>
    <StyledTypography settings={{ dark: true }}>{children}</StyledTypography>
  </StyledListItem>
);

ListItem.propTypes = {
  children: PropTypes.string,
};

const Security = () => (
  <StyledRoot>
    <StyledDuck>
      <Svg path="images/artwork-duck-guard" />
    </StyledDuck>
    <StyledLockIcon />
    <StyledTitle>Your data is only yours</StyledTitle>
    <StyledList>
      <ListItem>Real-time and archived sessions are end-to-end encrypted</ListItem>
      <ListItem>Any stored data is encrypted at rest</ListItem>
      <ListItem>Encryption keys are unique per app, per session</ListItem>
      <ListItem>Defense-in-depth: authentication, encryption, isolation, logging, and many more</ListItem>
    </StyledList>
    <StyledBottom>
      <StyledThemeLink to="https://www.cossacklabs.com/case-studies/appspector" target="_blank" rel="noopener">
        Learn more
      </StyledThemeLink>
      <Button icon="chat" size="large" variant="bordered" onClick={openCrisp}>
        Ask away
      </Button>
    </StyledBottom>
  </StyledRoot>
);

export default Security;
