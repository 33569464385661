import React from "react";
import styled from "styled-components";
import { mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { Container } from "src/theme/layout";
import { Typography, Link } from "src/theme/elements";
import { graphql, useStaticQuery } from "gatsby";
import BlogPost from "./elements/BlogPost";
import { colors } from "src/theme/variables";

const StyledRoot = styled.section`
  background-image: linear-gradient(to bottom, ${colors.silver}, ${colors.white});
`;

const StyledTypography = styled(Typography)``;

const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 48px;
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h3",
    dark: true,
  },
})`
  margin-bottom: 40px;
  text-align: center;

  ${mediaLg`
    margin-bottom: 30px;
  `}
`;

const StyledScroller = styled.div`
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: 10px;

  ${mediaLg`
    margin-left: -33px;
    margin-right: -33px;
  `}

  ${mediaMd`
    overflow-y: hidden;
    overflow-x: auto;
    width: fit-content;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  `};
`;

const StyledPosts = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 28px;

  ${mediaMd`
    justify-content: flex-start;

    &::after, &::before {
      content: '';
      width: 20px;
      flex-shrink: 0;
    }
  `};
`;

const StyledPost = styled.div`
  max-width: 320px;
  margin: 25px 50px;

  ${mediaLg`
    max-width: 264px;
    margin: 15px 33px;
  `}

  ${mediaMd`
    margin-left: 0;
    margin-right: 0;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 40px;
    }
  `}

  ${mediaSm`
    &:not(:last-child) {
      margin-right: 36px;
    }
  `}
`;

const StyledMore = styled(Link)`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;

  ${StyledTypography} {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;

    ${mediaSm`
      font-size: 16px;
      line-height: 25px;
    `}
  }
`;

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      ghostPosts: allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: 3, skip: 0) {
        edges {
          node {
            slug
            feature_image
            title
            primary_tag {
              name
            }
            primary_author {
              name
              profile_image
            }
          }
        }
      }
    }
  `);

  return (
    <StyledRoot>
      <StyledContainer>
        <StyledTitle>Get to know us through our blog</StyledTitle>
        <StyledScroller>
          <StyledPosts>
            {data.ghostPosts.edges.map(post => (
              <StyledPost key={post.node.uuid}>
                <BlogPost post={post.node} />
              </StyledPost>
            ))}
          </StyledPosts>
        </StyledScroller>
        <StyledMore to="/blog">
          <StyledTypography settings={{ dark: true }}>READ MORE</StyledTypography>
        </StyledMore>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Blog;
