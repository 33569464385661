import { keyframes } from "styled-components";

export const bounce = keyframes`
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-4px);
  }

  75% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0);
  }
`;
