import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { darken } from "polished";
import { mediaSm, mediaLg, mediaMd, transition } from "src/theme/utils";
import { Typography, Svg, Link } from "src/theme/elements";
import { replaceHttpWithHttps } from "src/utils";
import { colors, fonts } from "src/theme/variables";

const StyledRoot = styled.div`
  border-radius: 12px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 93, 0.06);
  background-color: ${colors.white};
  overflow: hidden;
  height: 100%;
  will-change: transform;
  transition: ${transition(["transform", "box-shadow"])};

  &:hover {
    transform: scale(1.03);
    box-shadow: 1px 7px 10px 0 ${darken(0.05, "#efeff5")};

    ${mediaMd`
      transform: none;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 93, 0.06);
    `}
  }
`;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: 220px;
  object-fit: cover;

  ${mediaLg`
    height: 168px;
  `}

  ${mediaSm`
    height: 160px;
  `}
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  height: calc(100% - 220px);

  ${mediaLg`
    padding: 16px;
    height: calc(100% - 168px);
  `}

  ${mediaSm`
    height: calc(100% - 160px);
  `}
`;

const StyledTag = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.crimson};
  text-transform: uppercase;
  font-family: ${fonts.secondary};
  font-weight: 700;
  margin-bottom: 7px;
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h6",
    dark: true,
  },
})`
  width: 100%;
  margin-bottom: 41px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mediaLg`
    font-size: 18px;
    line-height: 24px;
  `}
`;

const StyledInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: auto;
`;

const StyledAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;

  ${mediaLg`
    width: 36px;
    height: 36px;
  `}
`;

const StyledRead = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  color: ${colors.darkGray};
  text-transform: uppercase;
  font-weight: 500;
  margin-left: auto;
  margin-right: 12px;
  transition: ${transition("color")};

  ${StyledRoot}:hover & {
    color: #6f6f7d;
  }
`;

const StyledArrow = styled(Svg).attrs({
  path: "icons/arrow-right",
})`
  width: 24px;
  color: ${colors.darkGray};
  transition: ${transition("color")};

  ${StyledRoot}:hover & {
    color: #6f6f7d;
  }

  ${mediaLg`
    width: 20px;
  `}
`;

const BlogPost = ({ post }) => (
  <StyledRoot>
    <Link to={`/blog/${post.slug}`}>
      {post.feature_image && (
        <StyledImage className="lazyload" data-src={replaceHttpWithHttps(post.feature_image)} alt={post.title} />
      )}
      <StyledContent>
        {post.primary_tag && <StyledTag>{post.primary_tag.name}</StyledTag>}
        <StyledTitle title={post.title}>{post.title}</StyledTitle>
        <StyledInfo>
          {post.primary_author?.profile_image && (
            <StyledAvatar
              className="lazyload"
              data-src={replaceHttpWithHttps(post.primary_author.profile_image)}
              title={post.primary_author.name}
            />
          )}
          <StyledRead>Read post</StyledRead>
          <StyledArrow />
        </StyledInfo>
      </StyledContent>
    </Link>
  </StyledRoot>
);

BlogPost.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    primary_tag: PropTypes.shape({
      name: PropTypes.string,
    }),
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
    slug: PropTypes.string,
  }),
};

export default BlogPost;
