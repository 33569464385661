import React from "react";
import styled from "styled-components";
import { mediaLg, mediaSm, transition } from "src/theme/utils";
import { Typography, Svg } from "src/theme/elements";
import { graphql, useStaticQuery } from "gatsby";
import { colors } from "src/theme/variables";

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h6",
  },
})`
  text-transform: uppercase;
  margin-bottom: 6px;
  font-weight: 500;
  letter-spacing: 1px;

  ${mediaLg`
    font-size: 18px;
    line-height: 28px;
  `}

  ${mediaSm`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2px;
  `}
`;

const StyledPlatforms = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -14px;
  margin-right: -14px;

  ${mediaLg`
    margin-left: -12px;
    margin-right: -12px;
  `}

  ${mediaSm`
    margin-left: -10px;
    margin-right: -10px;
  `}
`;

const StyledPlatform = styled.a`
  height: 56px;
  padding: 7px 14px;
  color: ${colors.lightGray};
  transition: ${transition("color")};

  &:hover {
    color: ${colors.middleGray};
  }

  &:active {
    color: ${colors.darkGray};
  }

  ${mediaLg`
    height: 50px;
    padding: 7px 12px;
  `}

  ${mediaSm`
    height: 40px;
    padding: 4px 10px;
  `}

  svg {
    height: 100%;
  }
`;

const Platforms = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//data/components/platforms.md/" } }) {
        edges {
          node {
            frontmatter {
              platforms {
                icon
                link
              }
            }
          }
        }
      }
    }
  `);

  const { platforms } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div>
      <StyledTitle>We support</StyledTitle>
      <StyledPlatforms>
        {platforms.map(({ link, icon }, index) => (
          <StyledPlatform key={index} href={link} target="_blank" rel="noopener">
            <Svg path={icon} />
          </StyledPlatform>
        ))}
      </StyledPlatforms>
    </div>
  );
};

export default Platforms;
