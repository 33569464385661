import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { sizes, colors } from "src/theme/variables";
import { mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { Container, Row, Column } from "src/theme/layout";
import { Image, Svg } from "src/theme/elements";
import debounce from "lodash/debounce";

const StyledRoot = styled.section`
  position: relative;
  background-color: ${colors.silver};
`;

const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 50px;

  ${mediaSm`
    padding-top: 32px;
    padding-bottom: 32px;
  `}
`;

const StyledColumn = styled(Column)`
  position: relative;
  z-index: 1;
  padding-left: 0;
  padding-right: 0;

  ${mediaLg`
    display: flex;
    justify-content: center;
    padding-left: ${sizes.gutter}px;
    padding-right: ${sizes.gutter}px;
  `}
`;

const StyledBackground = styled(Svg).attrs({
  path: "images/companies-background",
})`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  height: 100%;
  display: block;
`;

const StyledScroller = styled.div`
  padding-top: 103px;
  padding-bottom: 103px;

  ${mediaSm`
    min-width: 100vw;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: hidden;
    overflow-x: auto;
  `}
`;

const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: -104px;
  margin-right: -104px;

  ${mediaLg`
    margin-left: -70px;
    margin-right: -70px;
    max-width: 1254px;
  `}

  ${mediaMd`
    margin-left: -40px;
    margin-right: -40px;
    max-width: 904px;
  `}

  ${mediaSm`
    justify-content: flex-start;
    margin-left: -35px;
    margin-right: -35px;
    max-width: none;
    width: 1055px;
  `}
`;

const logosLg = [208, 224, 178, 85, 140, 152, 103, 166, 198, 151];
const logosMd = [178, 191, 152, 64, 120, 130, 88, 142, 170, 129];

const StyledImage = styled(Image)`
  margin: 32px 104px;

  ${mediaLg`
    margin: 20px 70px;

    ${() =>
      logosLg.reduce((a, w, i) => {
        a += `
          &:nth-child(${i + 1}) {
            width: ${w}px;
          }
        `;

        return a;
      }, "")}
  `}

  ${mediaMd`
    margin: 32px 40px;

    ${() =>
      logosMd.reduce((a, w, i) => {
        a += `
          &:nth-child(${i + 1}) {
            width: ${w}px;
          }
        `;

        return a;
      }, "")}
  `}

  ${mediaSm`
    margin: 15px 35px;

    &:nth-child(6) {
      margin-left: calc(27px * 2);
    }
  `}
`;

const runAnimation = el => {
  let currentScroll = el.scrollLeft;
  let reverse = false;
  const availableScrollWidth = el.scrollWidth - el.offsetWidth;

  if (availableScrollWidth > 0) {
    let raf;
    const animation = () => {
      if (currentScroll > availableScrollWidth) {
        currentScroll = availableScrollWidth;
        reverse = true;
      }

      if (currentScroll < 0) {
        currentScroll = 0;
        reverse = false;
      }

      el.scrollLeft = currentScroll;

      if (reverse) {
        currentScroll -= 1;
      } else {
        currentScroll += 1;
      }

      raf = window.requestAnimationFrame(animation);
    };

    raf = window.requestAnimationFrame(animation);

    return () => window.cancelAnimationFrame(raf);
  }

  return false;
};

const Companies = () => {
  const scrollerRef = useRef(null);

  useEffect(() => {
    if (scrollerRef !== null && scrollerRef.current) {
      let isAnimationRunning = true;
      let stopAnimation = runAnimation(scrollerRef.current);

      const debouncedRunAnimation = debounce(() => {
        stopAnimation = runAnimation(scrollerRef.current);
        isAnimationRunning = true;
      }, 700);

      const handleTouch = () => {
        stopAnimation && stopAnimation();
        isAnimationRunning = false;
      };

      const handleScroll = () => {
        if (!isAnimationRunning) {
          debouncedRunAnimation();
        }
      };

      const handleResize = () => {
        stopAnimation && stopAnimation();
        debouncedRunAnimation();
      };

      ["mousedown", "touchstart"].forEach(e => scrollerRef.current.addEventListener(e, handleTouch, { passive: true }));
      scrollerRef.current.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);

      return () => {
        if (scrollerRef.current !== null) {
          ["mousedown", "touchstart"].forEach(e =>
            scrollerRef.current.removeEventListener(e, handleTouch, { passive: true }),
          );
          scrollerRef.current.removeEventListener("scroll", handleScroll);
        }
        window.removeEventListener("resize", handleResize);
        stopAnimation && stopAnimation();
      };
    }
  }, []);

  return (
    <StyledRoot>
      <StyledContainer>
        <Row>
          <StyledColumn xl={11} lg={12} sm={4} space={{ xl: 0.5, lg: 0, sm: 0 }}>
            <StyledBackground />
            <StyledScroller ref={scrollerRef}>
              <StyledImages>
                <StyledImage path="/assets/new/logo-autodesk.png" alt="Autodesk" />
                <StyledImage path="/assets/new/logo-lufthansa.png" alt="Lufthansa" />
                <StyledImage path="/assets/new/logo-accenture.png" alt="Accenture" />
                <StyledImage path="/assets/new/logo-sky.png" alt="Sky" />
                <StyledImage path="/assets/new/logo-verizon.png" alt="Verizon" />
                <StyledImage path="/assets/new/logo-helbiz.png" alt="Helbiz" />
                <StyledImage path="/assets/new/logo-base.png" alt="Base" />
                <StyledImage path="/assets/new/logo-waves.png" alt="Waves" />
                <StyledImage path="/assets/new/logo-madmobile.png" alt="MadMobile" />
                <StyledImage path="/assets/new/logo-yalantis.png" alt="Yalantis" />
              </StyledImages>
            </StyledScroller>
          </StyledColumn>
        </Row>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Companies;
