import React from "react";
import styled from "styled-components";
import { mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { Typography, Svg } from "src/theme/elements";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { fonts } from "src/theme/variables";

const StyledTypography = styled(Typography)``;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${mediaSm`
    align-items: center;
    padding-top: 40px;
    padding-bottom: 30px;
  `}
`;

const StyledQuoteIcon = styled(Svg).attrs({
  path: "icons/quote",
})`
  align-self: flex-start;
  color: #c6c6d1;
  width: 34px;
  margin-bottom: 30px;

  ${mediaLg`
    width: 29px;
    margin-bottom: 23px;
  `}

  ${mediaSm`
    width: 30px;
    margin-bottom: 27px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h3",
    dark: true,
  },
})`
  font-size: 30px;
  line-height: 44px;
  margin-bottom: 26px;
  text-align: right;
  font-family: ${fonts.tertiary};

  ${mediaLg`
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 25px;
  `}

  ${mediaMd`
    margin-bottom: 13px;
  `}

  ${mediaSm`
    text-align: center;
  `}
`;

const StyledAuthor = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${mediaSm`
    flex-direction: column-reverse;
  `}
`;

const StyledName = styled(Typography).attrs({
  settings: {
    dark: true,
  },
})`
  display: block;
  font-size: 16px;
  line-height: 25px;
  text-align: right;
  font-weight: 500;

  ${mediaSm`
    text-align: center;
  `}
`;

const StyledPosition = styled(Typography).attrs({
  settings: {
    dark: true,
  },
})`
  display: block;
  font-size: 16px;
  line-height: 25px;
  text-align: right;
  font-family: ${fonts.secondary};

  ${mediaSm`
    text-align: center;
  `}

  ${StyledTypography} {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
    text-decoration: underline;
  }
`;

const StyledAvatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: 16px;

  ${mediaLg`
    width: 60px;
    height: 60px;
  `}

  ${mediaSm`
    margin-left: 0;
    margin-bottom: 6px;
  `}
`;

const Quote = () => {
  const data = useStaticQuery(graphql`
    query {
      avatarBig: file(relativePath: { eq: "new/bruno.png" }) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      avatarSmall: file(relativePath: { eq: "new/bruno.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <StyledRoot>
      <StyledQuoteIcon />
      <StyledTitle>I’m shocked. This is... wow. 👏</StyledTitle>
      <StyledAuthor>
        <div>
          <StyledName>Bruno Muniz</StyledName>
          <StyledPosition>
            iOS Engineer at <StyledTypography>Elixxir.io</StyledTypography>
          </StyledPosition>
        </div>
        <StyledAvatar>
          <Img
            fixed={[
              {
                ...data.avatarSmall.childImageSharp.fixed,
                media: `(max-width: 1439px)`,
              },
              data.avatarBig.childImageSharp.fixed,
            ]}
          />
        </StyledAvatar>
      </StyledAuthor>
    </StyledRoot>
  );
};
export default Quote;
