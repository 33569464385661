import React from "react";
import styled from "styled-components";
import { colors, sizes } from "src/theme/variables";
import { mediaLg, mediaMd, mediaSm } from "src/theme/utils";
import { Container, Row, Column } from "src/theme/layout";
import { Svg } from "src/theme/elements";
import { MiddleHouseLabel, RightHouseLabel, SideHouseLabel } from "./Label";

const StyledRoot = styled.div`
  position: relative;
`;

const StyledContainer = styled(Container)`
  position: relative;

  ${mediaSm`
    max-width: none;
    padding: 0;
    margin: 0;
  `}
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: flex-end;

  ${mediaSm`
    margin-left: 0;
    margin-right: 0;
  `}
`;

const StyledMiddleHouseContainer = styled.div`
  position: relative;
  z-index: 3;
`;

const StyledMiddleHouseCircle = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  transform: translate(-85px, -56px);
  width: 210px;
  height: 210px;
  border-radius: 50%;
  background-image: linear-gradient(44deg, #3f3f5d 14%, #7ca26b 86%);

  ${mediaLg`
    transform: translate(-62px, -45px);
    width: 158px;
    height: 158px;
  `}

  ${mediaSm`
    transform: translate(0, -28px);
    width: 105px;
    height: 105px;
  `}
`;

const StyledMiddleHouseColumn = styled(Column)`
  ${mediaSm`
    margin-left: -40px;
    width: 180px;
    padding: 0;
    margin-right: 30px;
  `}
`;

const StyledMiddleHouse = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 15px;

  ${mediaLg`
    padding: 0 12px;
  `}

  ${mediaMd`
    padding: 0;
    margin: 0 -5px;
  `}

  ${mediaSm`
    padding: 0;

    ${MiddleHouseLabel} {
      display: none;
    }
  `}
`;

const StyledRightHouseColumn = styled(Column)`
  ${mediaSm`
    width: 186px;
    padding: 0;
    margin: 0;
  `}
`;

const StyledRightHouse = styled.div`
  position: relative;
  z-index: 3;
  padding-left: 18px;

  ${mediaLg`
    padding-left: 10px;
  `}

  ${mediaMd`
    padding-left: 20px;
    padding-right: 30px;
  `}

  ${mediaSm`
    padding-left: 0;

    ${RightHouseLabel} {
      display: none;
    }
  `}
`;

const StyledSideHouseContainer = styled.div`
  position: relative;
`;

const StyledSideHouseCircle = styled.div`
  position: absolute;
  z-index: -1;
  top: 148px;
  left: 24px;
  transform: translateX(-100%);
  width: 124px;
  height: 124px;
  border-radius: 50%;
  background-image: linear-gradient(305deg, #3f3f5d 6%, #7ca26b 94%);

  ${mediaLg`
    top: 111px;
    left: 20px;
    width: 93px;
    height: 93px;
  `}

  ${mediaSm`
    width: 62px;
    height: 62px;
  `}
`;

const StyledSideHouseSmallCircle = styled.div`
  position: absolute;
  z-index: -1;
  left: calc(100% - 96px / 2);
  top: calc(100% - 70px);
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-image: linear-gradient(44deg, #3f3f5d 14%, #7ca26b 86%);

  ${mediaSm`
    width: 56px;
    height: 56px;
    left: calc(100% - 56px / 2);
    top: calc(100% - 40px);
  `}
`;

const StyledSideHouse = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: -133px;
  width: 234px;

  ${mediaLg`
    right: -73px;
    width: 176px;
  `}

  ${mediaMd`
    right: -40px;
  `}

  ${mediaSm`
    right: -21px;
    width: 117px;

    ${SideHouseLabel} {
      display: none;
    }
  `}
`;

const StyledScreenColumn = styled(Column)`
  ${mediaSm`
    max-width: 340px;
    width: calc(100% - 40px);
    padding: 0 ${sizes.gutter / 2}px;
    margin: 0 auto;
  `}
`;

const StyledScreen = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 -5px -74px;

  ${mediaLg`
    padding: 0 46px;
    margin: 0 0 -10px;
  `}

  ${mediaMd`
    padding: 0;
    margin: 0 -5px;
  `}

  ${mediaSm`
    margin: 0 0 36px;
    padding: 0;
  `}
`;

const StyledMobileLabels = styled.div`
  display: none;

  ${mediaSm`
    width: 320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  `}
`;

const Scene = () => (
  <StyledRoot>
    <StyledContainer>
      <StyledMobileLabels>
        <MiddleHouseLabel
          data={{
            img: "/assets/new/frederic.png",
            text: "My app crashes",
            color: colors.darkGold,
            monitor: "Logs",
            path: "images/path-frederic-mobile",
          }}
        />
        <RightHouseLabel
          data={{
            img: "/assets/new/joshua.png",
            text: "API is not working",
            color: colors.green,
            monitor: "Core Data",
            path: "images/path-joshua-mobile",
          }}
        />
        <SideHouseLabel
          data={{
            img: "/assets/new/helen.png",
            text: "I’m testing in the dark",
            color: colors.purple,
            monitor: "HTTP",
            path: "images/path-helen-mobile",
          }}
        />
      </StyledMobileLabels>
      <Row>
        <StyledScreenColumn xl={4} lg={6} md={4} sm={0} space={{ xl: 7, lg: 6, md: 3 }}>
          <StyledScreen>
            <Svg path="images/screen" />
          </StyledScreen>
        </StyledScreenColumn>
      </Row>
      <StyledRow>
        <StyledMiddleHouseColumn xl={4} lg={4} md={3} sm={0} space={{ xl: 4, lg: 4, md: 1 }}>
          <StyledMiddleHouse>
            <MiddleHouseLabel
              data={{
                img: "/assets/new/frederic.png",
                text: "My app crashes",
                color: colors.darkGold,
                monitor: "Logs",
                path: "images/path-frederic",
              }}
            />
            <StyledMiddleHouseContainer>
              <StyledMiddleHouseCircle />
              <Svg path="images/artwork-mansion" />
            </StyledMiddleHouseContainer>
          </StyledMiddleHouse>
        </StyledMiddleHouseColumn>
        <StyledRightHouseColumn xl={4} lg={4} md={4} sm={0}>
          <StyledRightHouse>
            <RightHouseLabel
              data={{
                img: "/assets/new/joshua.png",
                text: "API is not working",
                color: colors.green,
                monitor: "Core Data",
                path: "images/path-joshua",
              }}
            />
            <Svg path="images/artwork-header-house" />
          </StyledRightHouse>
        </StyledRightHouseColumn>
      </StyledRow>
      <StyledSideHouse>
        <SideHouseLabel
          data={{
            img: "/assets/new/helen.png",
            text: "I’m testing in the dark",
            color: colors.purple,
            monitor: "HTTP",
            path: "images/path-helen",
          }}
        />
        <StyledSideHouseContainer>
          <StyledSideHouseCircle />
          <StyledSideHouseSmallCircle />
          <Svg path="images/artwork-header-scraper" />
        </StyledSideHouseContainer>
      </StyledSideHouse>
    </StyledContainer>
  </StyledRoot>
);

export default Scene;
