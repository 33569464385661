import React from "react";
import styled from "styled-components";
import { Container } from "src/theme/layout";
import { Typography, Button, Svg } from "src/theme/elements";
import { vwMd, mediaLg, mediaMd, mediaSm } from "src/theme/utils";
import { colors, fonts } from "src/theme/variables";

const StyledRoot = styled.section`
  position: relative;
  padding-bottom: ${vwMd(24)};
  background-color: ${colors.blackish};
`;

const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 66px 0 88px;
  margin: 0 -65px;

  ${mediaLg`
    padding: 42px 0 46px;
    margin: 0 -51px;
  `}

  ${mediaMd`
    flex-direction: column;
  `}

  ${mediaSm`
    padding: 10px 0 30px;
    margin: 0;
  `}
`;

const StyledItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 65px;

  ${mediaLg`
    padding: 20px 51px;
  `}

  ${mediaSm`
    flex: none;
    width: 100%;
    padding: 30px 0;
  `}
`;

const StyledIcon = styled(Svg)`
  height: 122px;
  margin-bottom: 41px;

  svg {
    height: 100%;
  }

  ${mediaLg`
    height: 88px;
    margin-bottom: 24px;
  `}

  ${mediaSm`
    height: 60px;
    margin-bottom: 12px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h4",
  },
})`
  margin-bottom: 6px;

  ${mediaLg`
    margin-bottom: 11px;
  `}

  ${mediaMd`
    margin-bottom: 9px;
  `}

  ${mediaSm`
    margin-bottom: 1px;
  `}
`;

const StyledText = styled(Typography)`
  font-family: ${fonts.secondary};
`;

const StyledButtons = styled.div`
  display: none;

  ${mediaSm`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: -8px;
    margin-right: -8px;
  `}
`;

const StyledButton = styled.div`
  width: calc(50% - 16px);
  max-width: 256px;
  margin: 8px;
`;

const StyledButtonHint = styled(Typography)`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const StyledCurve = styled(Svg).attrs({
  path: "images/curve",
  preserveAspectRatio: "none",
})`
  position: absolute;
  z-index: 2;
  left: -1px;
  right: -1px;
  bottom: -1px;
  width: calc(100% + 2px);
  color: ${colors.white};
`;

const items = [
  {
    icon: "images/realtime-pulse",
    title: "Realtime Access",
    text: "Analyze what is going on in your app at any given moment",
  },
  {
    icon: "images/replay-time",
    title: "History Replay",
    text: "Find and reproduce bugs that happend even weeks ago",
  },
  {
    icon: "images/remote-eye",
    title: "Remote Control",
    text: "Connect and control your app on any user device",
  },
];

const Benefits = () => (
  <StyledRoot>
    <Container>
      <StyledButtons>
        <StyledButton>
          <Button to="https://app.appspector.com/register">Try for free</Button>
        </StyledButton>
        <StyledButton>
          <Button to="/pricing" variant="inverted">
            See pricing
          </Button>
        </StyledButton>
        <StyledButtonHint>No credit card required. Full-featured 14-day trial</StyledButtonHint>
      </StyledButtons>
      <StyledItems>
        {items.map(({ title, text, icon }, index) => (
          <StyledItem key={index}>
            <StyledIcon path={icon}></StyledIcon>
            <StyledTitle>{title}</StyledTitle>
            <StyledText>{text}</StyledText>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
    <StyledCurve />
  </StyledRoot>
);

export default Benefits;
