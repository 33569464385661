import React from "react";
import Layout from "../components/Layout";
import LogoImagePath from "../images/logo.png";

import MainScreen from "src/elements/pages/index/MainScreen";
import Benefits from "src/elements/pages/index/Benefits";
import Features from "src/elements/pages/index/Features";
import Companies from "src/elements/pages/index/Companies";
import Demo from "src/elements/pages/index/Demo";
import Blog from "src/elements/pages/index/Blog";
import Scope from "src/elements/pages/index/Scope";
import Header from "src/elements/UAInvasionHeader";
import Footer from "src/elements/Footer";
import Trial from "src/elements/Trial";
import Contact from "src/elements/Contact";
import { PageReset, StyledPageContainer } from "../components/helpers";

const IndexPage = () => (
  <Layout header={<Header />} footer={<Footer />}>
    <PageReset />
    <StyledPageContainer itemScope itemType="http://schema.org/SoftwareApplication">
      <MainScreen />
      <Benefits />
      <Features />
      <Scope />
      <Demo />
      <Companies />
      <Blog />
      <Trial page="index" />
      <Contact />

      <meta itemProp="applicationCategory" content="Developer Tools" />
      <meta itemProp="name" content="AppSpector" />
      <meta itemProp="downloadUrl" content="https://app.appspector.com/register" />
      <meta itemProp="logo" content={`https://appspector.com${LogoImagePath}`} />
    </StyledPageContainer>
  </Layout>
);

export default IndexPage;
