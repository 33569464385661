import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { breakpoints, colors } from "src/theme/variables";
import { mediaSm, mediaDemo, mediaLg, vwMd, transition } from "src/theme/utils";
import { Container } from "src/theme/layout";
import { Typography, Button, Loader, Svg } from "src/theme/elements";
import { liveDemoUrl } from "src/constants";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useMedia } from "src/components/Hooks";

const StyledRoot = styled.section.attrs({
  id: "live-demo",
  "data-section-color": "dark",
})`
  position: relative;
  padding-top: 140px;
  padding-bottom: ${vwMd(24)};
  background-image: linear-gradient(to bottom, ${colors.blackish}, #545565);

  ${mediaLg`
    padding-top: 120px;
  `}

  ${mediaSm`
    padding-top: 104px;
  `}
`;

const StyledContainer = styled(Container)`
  padding-top: 105px;
  padding-bottom: 110px;

  ${mediaLg`
    padding-top: 80px;
    padding-bottom: 72px;
  `}

  ${mediaSm`
    padding-bottom: 48px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h2",
  },
})`
  margin-bottom: 55px;
  text-align: center;

  ${mediaLg`
    margin-bottom: 27px;
  `}

  ${mediaDemo`
    display: none;
  `}
`;

const StyledVideoTitle = styled(Typography).attrs({
  settings: {
    tag: "h2",
  },
})`
  display: none;

  ${mediaDemo`
    display: block;
    text-align: center;
    margin-bottom: 34px;
  `}
`;

const StyledDemo = styled.div`
  position: relative;
`;

const StyledAppContainer = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 20px;
  overflow: hidden;

  ${mediaDemo`
    border-radius: 0;
    margin-bottom: 45px;
  `}
`;

const StyledPlaceholder = styled.div`
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
  filter: blur(10px);
  transform: translate3d(0, 0, 0);
  opacity: ${({ hide }) => hide && 0};

  ${mediaDemo`
    filter: blur(5px);
  `}
`;

const StyledApp = styled.iframe.attrs({
  src: liveDemoUrl,
  seamless: true,
})`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: none;

  ${mediaDemo`
    display: none;
  `}
`;

const StyledVideo = styled.video`
  display: none;

  ${mediaDemo`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    max-width: 100%;
    height: 100%;
    outline: none;
  `}
`;

const StyledButton = styled(Button)`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${mediaDemo`
    display: none;
  `}
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`;

const StyledPlayButton = styled.button`
  display: none;

  ${mediaDemo`
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 0;
    background-color: ${colors.crimson};
    color: #fff;
    transition: ${transition("background-color")};

    &:hover {
      background-color: ${colors.brightCrimson};
    }

    &:active {
      background-color: ${colors.darkCrimson};
    }
  `}

  svg {
    width: 48px;
  }
`;

const StyledAppButton = styled.div`
  display: none;

  ${mediaDemo`
    display: flex;
    justify-content: center;
  `}
`;

const StyledCurve = styled(Svg).attrs({
  path: "images/curve",
  preserveAspectRatio: "none",
})`
  display: block;
  position: absolute;
  bottom: -1px;
  right: -1px;
  left: -1px;
  width: calc(100% + 2px);
  color: ${colors.silver};
`;

const Demo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "new/app-screenshot.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1264) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const [isDemoActive, setIsDemoActive] = useState(false);
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isDemoBreakpoint = useMedia([`(min-width: ${breakpoints.demo}px)`], [true], false);

  const videoRef = useRef(null);

  const handleShowDemo = () => {
    setIsDemoActive(true);
    setIsLoading(true);
  };

  const handlePlayVideo = async () => {
    setIsVideoActive(true);
  };

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(false);

    if (isDemoBreakpoint) {
      setIsVideoActive(false);
    } else {
      setIsDemoActive(false);
    }
  }, [isDemoBreakpoint]);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
    }
  }, [isVideoActive]);

  return (
    <StyledRoot>
      <StyledContainer>
        <StyledTitle>Try our live demo ⚡</StyledTitle>
        <StyledVideoTitle>Watch demo video ⚡</StyledVideoTitle>
        <StyledDemo>
          <StyledAppContainer>
            <StyledPlaceholder hide={isDemoActive || isVideoActive}>
              <Img fluid={data.file.childImageSharp.fluid} />
            </StyledPlaceholder>

            {isDemoActive && <StyledApp onLoad={handleOnLoad} />}
            {isVideoActive && (
              <StyledVideo
                ref={videoRef}
                src="https://storage.googleapis.com/appspector-videos/appspector-demo-jun-5.mp4"
                muted
                controls
                playsInline
              />
            )}
          </StyledAppContainer>
          {isLoading && <StyledLoader />}

          {!isDemoActive && <StyledButton onClick={handleShowDemo}>Start Live Demo</StyledButton>}
          {!isVideoActive && (
            <StyledPlayButton aria-label="play demo video" onClick={handlePlayVideo}>
              <Svg path="icons/play" />
            </StyledPlayButton>
          )}
        </StyledDemo>
        <StyledAppButton>
          <Button to="https://app.appspector.com/register" size="large">
            Launch AppSpector
          </Button>
        </StyledAppButton>
      </StyledContainer>
      <StyledCurve />
    </StyledRoot>
  );
};

export default Demo;
