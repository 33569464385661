import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mediaLg, mediaSm } from "src/theme/utils";
import { Svg, Typography } from "src/theme/elements";
import { getRetinaPath } from "src/utils";
import { colors, fonts } from "src/theme/variables";

const StyledTypography = styled(Typography)``;

const StyledRoot = styled.div`
  position: relative;
  z-index: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  ${mediaLg`
    margin-bottom: 9px;
  `}

  ${mediaSm`
    align-items: flex-start;
    margin-bottom: 0;
  `}
`;

const StyledCircle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;

  ${mediaSm`
    display: none;
  `}
  ${StyledTypography} {
    line-height: 16px;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.middleGray};
    font-style: normal;
  }
`;

const StyledPath = styled(Svg)`
  margin-bottom: 10px;

  ${mediaLg`
    display: none;
  `}

  ${mediaSm`
    display: block;
  `}
`;

const StyledText = styled.div`
  border-radius: 20px;
  padding: 10px 16px;
  margin-bottom: 8px;
  text-align: center;
  white-space: nowrap;
  background-color: ${({ bg }) => bg};

  ${StyledTypography} {
    font-family: ${fonts.secondary};
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: ${colors.white};

    ${mediaLg`
      font-size: 13px;
      line-height: 16px;
    `}
  }
`;

const StyledImg = styled.div`
  width: 90px;
  height: 104px;

  ${mediaLg`
    width: 72px;
    height: 83px;
  `}
  ${mediaSm`
    width: 56px;
    height: 56px;
    overflow: hidden;
    border-radius: 50%;
  `}
  img {
    width: 100%;
  }
`;

const Label = ({ className, data }) => (
  <StyledRoot className={className}>
    <StyledCircle>
      <StyledTypography>{data.monitor}</StyledTypography>
    </StyledCircle>
    <StyledPath path={data.path} />
    <StyledText bg={data.color}>
      <StyledTypography>{data.text}</StyledTypography>
    </StyledText>
    <StyledImg>
      <img src={data.img} srcSet={getRetinaPath(data.img)} alt={data.text} />
    </StyledImg>
  </StyledRoot>
);

Label.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    color: PropTypes.string,
    monitor: PropTypes.string,
    text: PropTypes.string,
    img: PropTypes.string,
    path: PropTypes.string
  })
};

export const MiddleHouseLabel = styled(Label)`
  transform: translateX(-100%);
  left: 306px;

  ${mediaLg`
    left: 250px;
  `}
  ${mediaSm`
    left: 0;
    transform: translateX(0);
    margin-bottom: -42px;
  `}
  ${StyledPath} {
    width: 24px;
    transform: translateX(50%);

    ${mediaSm`
      width: 96px;
      transform: translateX(0);
      position: absolute;
      top: calc(100% - 28px);
      left: 66px;
    `}
  }

  ${StyledCircle} {
    width: 60px;
    height: 60px;
    left: calc(50% + 34px);
    background-color: #383847;
    transform: translateY(-50%);

    ${mediaLg`
      width: 60px;
      height: 60px;
      top: -12px;
      left: 50%;
      transform: translate(-50%, -100%);
    `}
  }
`;

export const RightHouseLabel = styled(Label)`
  ${mediaLg`
    margin-left: -12px;
  `}
  ${mediaSm`
    align-self: flex-end;
    align-items: flex-end;
    margin-bottom: -30px;
  `}
  ${StyledPath} {
    width: 4px;

    ${mediaSm`
      width: 88px;
      position: absolute;
      top: calc(100% - 28px);
      right: 66px;
    `}
  }

  ${StyledCircle} {
    width: 72px;
    height: 72px;
    background-color: ${colors.blackish};
    top: -12px;
    left: 50%;
    transform: translate(-50%, -100%);

    ${mediaLg`
      width: 72px;
      height: 72px;
      top: -12px;
    `}
  }
`;

export const SideHouseLabel = styled(Label)`
  ${mediaLg`
    margin-left: -34px;
  `}
  ${mediaSm`
    margin-top: 0.4px;
    margin-left: 0;
  `}
  ${StyledPath} {
    width: 59px;
    transform: translateX(-50%);

    ${mediaSm`
      width: 88px;
      position: absolute;
      transform: translateX(0);
      top: calc(100% - 28px);
      left: 66px;
    `}
  }

  ${StyledCircle} {
    width: 60px;
    height: 60px;
    left: calc(50% - 69px);
    transform: translate(-100%, -50%);
    background-color: ${colors.blackish};

    ${mediaLg`
      width: 60px;
      height: 60px;
      top: -12px;
      left: 50%;
      transform: translate(-50%, -100%);
    `}
  }
`;
