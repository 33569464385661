import React from "react";
import styled from "styled-components";
import { sizes, colors, fonts } from "src/theme/variables";
import { mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { Typography, Svg } from "src/theme/elements";
import { Row, Column } from "src/theme/layout";
import { bounce } from "src/theme/keyframes";

const StyledRoot = styled(Row)`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  padding-top: 184px;
  padding-bottom: 184px;
  background-color: ${colors.white};

  ${mediaLg`
    padding-top: 184px;
    padding-bottom: 184px;
  `}

  ${mediaMd`
    padding-top: 0;
    padding-bottom: 98px;
    flex-direction: column-reverse;
  `}

  ${mediaSm`
    padding-top: 80px;
    padding-bottom: 84px;
  `}
`;

const StyledContent = styled(Column)`
  display: flex;
  flex-direction: column;
  padding-left: calc(24px + ${sizes.gutter}px);
  padding-right: calc(24px + ${sizes.gutter}px);

  ${mediaLg`
    padding-left: ${sizes.gutter / 2}px;
    padding-right: ${sizes.gutter / 2}px;
  `}

  ${mediaMd`
    padding-left: 0;
    padding-right: 0;
  `}

  ${mediaSm`
    padding-left: ${sizes.gutter / 2}px;
    padding-right: ${sizes.gutter / 2}px;
  `}
`;

const StyledArt = styled(Column)`
  position: relative;
  z-index: 1;
  padding-left: calc(24px + ${sizes.gutter}px);
  padding-right: calc(24px + ${sizes.gutter}px);

  ${mediaLg`
    padding-left: ${sizes.gutter}px;
    padding-right: ${sizes.gutter}px;
  `}

  ${mediaMd`
    max-width: 500px;
    margin-bottom: 130px;
    margin-left: auto;
  `}

  ${mediaSm`
    max-width: 450px;
    left: 85px;
    margin-bottom: 108px;
  `}
`;

const StyledLabel = styled(Typography)`
  color: ${colors.gold};
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 16px;

  ${mediaLg`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 9px;
  `}

  ${mediaMd`
    margin-bottom: 7px;
  `}

  ${mediaSm`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h3",
    dark: true,
  },
})`
  margin-bottom: 14px;

  ${mediaSm`
    margin-bottom: 11px;
  `}
`;

const StyledText = styled(Typography).attrs({
  settings: {
    tag: "p",
    dark: true,
  },
})`
  font-family: ${fonts.secondary};
  margin-bottom: 50px;

  ${mediaLg`
    margin-bottom: 40px;
  `}

  ${mediaSm`
    margin-bottom: 32px;
  `}
`;

const StyledLogsSvg = styled(Svg).attrs({
  path: "images/shot-logs",
})`
  display: block;
  width: 100%;
`;

const StyledPhoneSvg = styled(Svg).attrs({
  path: "images/artwork-phone-traffic",
})`
  position: absolute;
  z-index: -1;
  width: 381px;
  top: 100%;
  left: calc(-381px + 24px + ${sizes.gutter}px);
  transform: translateY(-79px);

  ${mediaLg`
    width: 306px;
    left: calc(-306px + ${sizes.gutter}px);
    transform: translateY(-45px);
  `}

  ${mediaMd`
    width: 316px;
    left: calc(-316px + 30px + ${sizes.gutter}px);
    transform: translateY(-85px);
  `}

  ${mediaSm`
    width: 228px;
    left: -105px;
    transform: translateY(-47px);
  `}
`;

const StyledBackSvg = styled(Svg).attrs({
  path: "images/backshape-yellow",
})`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  height: 100%;
  right: 388px;

  ${mediaLg`
    right: 278px;
  `}

  ${mediaSm`
    right: 72px;
  `}
`;

const StyledArrow = styled(Svg).attrs({
  path: "icons/double-arrow",
})`
  width: 24px;
  color: ${colors.gold};
  animation: ${bounce} 2s linear infinite;
`;

const LogsDetails = () => (
  <StyledRoot>
    <StyledBackSvg />
    <StyledContent xl={6} lg={6} md={7} sm={4} space={{ md: 0.5 }}>
      <StyledLabel>History replay</StyledLabel>
      <StyledTitle>AppSpector is like a time machine </StyledTitle>
      <StyledText>It lets you replay any user sessions from the past</StyledText>
      <StyledArrow />
    </StyledContent>
    <StyledArt xl={6} lg={6} md={5} sm={4} space={{ md: 3 }}>
      <StyledPhoneSvg />
      <StyledLogsSvg />
    </StyledArt>
  </StyledRoot>
);

export default LogsDetails;
